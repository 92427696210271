
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { Helmet } from "react-helmet";
import rightImg from "images/about-hero-right.png";
import SectionHero from "./SectionHero";


function PageHome() {
    return (
        <div className="nc-PageAbout overflow-hidden relative">
            <Helmet>
                <title>PackageFactory ApS</title>
            </Helmet>

            <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
                <SectionHero
                    rightImg={rightImg}
                    heading="PackageFactory"
                    btnText=""
                    subHeading="We are building new services and updating this site to support our new strategy. See you soon :)"
                />
                <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
                    In the meantime you can contact us by <a style={{ textDecoration:"underline" }} href="mailto:info@packagefactory.dk">email.</a>
                </span>
            </div>
        </div>
    );
}

export default PageHome;
