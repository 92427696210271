import NcImage from "shared/NcImage/NcImage";
import rightImg from "images/OutWash.png";

export interface People {
    id: string;
    name: string;
    href: string;
    description: JSX.Element;
    image: any;
}

const FOUNDER_DEMO: People[] = [
    {
        id: "1",
        name: `OutWash`,
        description: <p><b>Responsive web design & development.</b> <br /> Built using Umbraco and React for optimal speed and user experience. On top of Umbraco is a custom developed booking management system, for planning and handling of orders.</p>,
        image: rightImg,
        href: "https://outwash.dk"
    }
];

const SectionCards = () => {
    return (
        <div className="nc-SectionFounder relative">
            <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-3 xl:gap-x-12">
                {FOUNDER_DEMO.map((item) => (
                    <div key={item.id} className="max-w-sm">
                        <a href={item.href} target="_blank" className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
                            <NcImage
                                containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
                                className="absolute inset-0 object-cover"
                                src={item.image}
                            />
                            <br />
                            {item.name}
                        </a>
                        <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
                            {item.description}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SectionCards;
