import rightImg from "images/Street+Student.png";
import React, { FC } from "react";
import SectionCards from "./SectionCards";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

export interface PageReferencesProps {
    className?: string;
}

const PageReferences: FC<PageReferencesProps> = ({ className = "" }) => {
    return (
        <div
            className={`nc-PageAbout overflow-hidden relative ${className}`}
            data-nc-id="PageAbout"
        >
            <Helmet>
                <title>References | PackageFactory ApS</title>
            </Helmet>

            {/* ======== BG GLASS ======== */}
            <BgGlassmorphism />

            <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
                <SectionHero
                    rightImg={rightImg}
                    heading="StreetStudent."
                    btnText=""
                    href="https://streetstudent.com"
                    subHeading={<p><b>Umbraco + React e-commerce</b> <br/> Responsive web design & development from scratch. Cybersource Payment Gateway integration, custom email engine and more.</p> }
                />

                <SectionCards />

            </div>
        </div>
    );
};

export default PageReferences;


//<div className="relative py-16">
//    <BackgroundSection />
//    <SectionClientSay />
//</div>

//<SectionStatistic />

//<SectionSubscribe2 />