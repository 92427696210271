import rightImg from "images/dowloadAppBG.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

export interface PageAboutProps {
    className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
    return (
        <div
            className={`nc-PageAbout overflow-hidden relative ${className}`}
            data-nc-id="PageAbout"
        >
            <Helmet>
                <title>About us | PackageFactory ApS</title>
            </Helmet>

            <BgGlassmorphism />

            <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
                <SectionHero
                    rightImg={rightImg}
                    heading="About Us."
                    btnText=""
                    subHeading={<>
                        <p>We provide IT-consulting and development services, and mainly focuses on startup companies or small-business.
                            We advise on digitization and provide the best e-business solutions to our customers. We do our best to provide great professionalism and quality.</p>
                        <br />
                        <p style={{ fontSize: "0.9em" }}>Our core competencies are to build business-critical IT solutions based on the latest cloud technologies, with a strong focus on operations and security.
                            We create value by taking responsibility for our customers' IT as if it was our own.
                            We put ourselves in the place of our customers and help them run their business more efficiently and safely, using the right technologies.</p>
                    </>}
                />
            </div>
        </div>
    );
};

export default PageAbout;