import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";

export interface WidgetFooterMenu {
    id: string;
    title: string;
    menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
    {
        id: "5",
        title: "",
        menus: [
            //{ href: "#", label: "Installation" },
            //{ href: "#", label: "Release Notes" },
            //{ href: "#", label: "Upgrade Guide" },
            //{ href: "#", label: "Browser Support" },
            //{ href: "#", label: "Editor Support" },
        ],
    },
    {
        id: "1",
        title: "",
        menus: [
            //{ href: "#", label: "Design features" },
            //{ href: "#", label: "Prototyping" },
            //{ href: "#", label: "Design systems" },
            //{ href: "#", label: "Pricing" },
            //{ href: "#", label: "Security" },
        ],
    },
    {
        id: "2",
        title: "",
        menus: [
            //{ href: "#", label: "Best practices" },
            //{ href: "#", label: "Support" },
            //{ href: "#", label: "Developers" },
            //{ href: "#", label: "Learn design" },
            //{ href: "#", label: "Releases" },
        ],
    },
    {
        id: "4",
        title: "",
        menus: [
            //{ href: "#", label: "Discussion Forums" },
            //{ href: "#", label: "Code of Conduct" },
            //{ href: "#", label: "Community Resources" },
            //{ href: "#", label: "Contributing" },
            //{ href: "#", label: "Concurrent Mode" },
        ],
    },
];

const Footer: React.FC = () => {
    const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
        return (
            <div key={index} className="text-sm">
                <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
                    {menu.title}
                </h2>
                <ul className="mt-5 space-y-4">
                    {menu.menus.map((item, index) => (
                        <li key={index}>
                            <a
                                key={index}
                                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                                href={item.href}
                            >
                                {item.label}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <div className="nc-Footer relative py-24 lg:py-32 border-t border-neutral-200 dark:border-neutral-700">
            <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
                <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
                    <div className="col-span-2 md:col-span-1">
                        {/*<Logo />*/}
                    </div>
                    <div className="col-span-2 flex items-center md:col-span-3">
                        <ul className="mt-5 space-y-4">
                            <li >
                                <p>PackageFactory ApS <span dangerouslySetInnerHTML={{ "__html": "&copy;" }} /> {new Date().getFullYear()}</p>
                            </li>
                            <li >
                                <a className="text-xs text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                                    href={`https://status.packagefactory.dk/`} target="_blank">
                                    Service health status
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
                {widgetMenus.map(renderWidgetMenuItem)}
            </div>
        </div>
    );
};

export default Footer;
