import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroProps {
    className?: string;
    rightImg: string;
    heading: ReactNode;
    subHeading: JSX.Element;
    btnText: string;
    href: string;
}

const SectionHero: FC<SectionHeroProps> = ({
    className = "",
    rightImg,
    heading,
    subHeading,
    btnText,
    href
}) => {
    return (
        <div
            className={`nc-SectionHero relative ${className}`}
            data-nc-id="SectionHero"
        >
            <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
                <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
                    <a href={href} target="_blank" className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
                        {heading}
                    </a>
                    <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
                        {subHeading}
                    </span>
                    {!!btnText && <ButtonPrimary href="/login">{btnText}</ButtonPrimary>}
                </div>
                <div className="flex-grow">
                    <a href={href} target="_blank" className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
                        <img className="w-full" src={rightImg} alt="" />
                    </a>                    
                </div>
            </div>
        </div>
    );
};

export default SectionHero;
